import React from "react"
import styled from "styled-components"
import {
  Container,
  Button,
  ButtonLink,
  Paragraph,
  Email,
} from "components/common"
// import { getGeoInfo } from "components/helpers"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import products from "../../../content/products.json"
import zineImage from "assets/img/eat-bitter-zine-web.jpg"
import zinePage5 from "assets/img/Eat Bitter-5.jpg"
import zinePage6 from "assets/img/Eat Bitter-6.jpg"
import zinePage7 from "assets/img/Eat Bitter-7.jpg"
import zinePage8 from "assets/img/Eat Bitter-8.jpg"
import Slider from "components/Slider"
import chilli from "assets/img/chilli-oil.jpg"
import ebteefront from "assets/img/eatbitterfront.jpg"
import ebteeback from "assets/img/eatbitterback.jpg"

// try and get country using IP
// if that fails show dropdown selector
// what if the person is located in USA but wants to gift to a friend in the UK?

// show products minus price.. ask user to select where they will be shipping it and then show price and allow purchase.
// explain why - we're trying to keep shipping costs down so lydia ships to places in and around the USA and Louise ships to UK and Europe.

// const ShopPage = () => {
//   console.log(edges)
//   const [country, setCountry] = useState({})
//   useEffect(() => {
//     async function fetchData() {
//       const result = await getGeoInfo()
//       setCountry(result)
//     }
//     fetchData()
//   }, [])

//   return (
//     <Layout>
//       <p>hello person from {country.name && country.name}</p>
//     </Layout>
//   )
// }

// export default ShopPage

const ProductList = styled.ul`
  max-width: 1200px;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
`
const PContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 0.6rem;
  font-size: 1rem;
`
const ProductTile = styled.li`
  margin: 2rem;
  list-style-type: none;
  padding: 2rem;
  background: black;
  color: white;
  transition: all 0.1s linear;
  max-width: 300px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  }
`
const ProductImage = styled.img`
  max-width: 300px;
`
const ProductTitle = styled.h3`
  margin: 0;
`

const ProductDescription = styled.div`
  color: rgb(200, 200, 200);
  margin-bottom: 1rem;
  font-size: 0.8rem;
  line-height: 1.4;
  width: 100%;
`

const ProductPrice = styled.div``
const ShoppingBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  z-index: 8;
`

const CartButton = styled.button`
height: 1.8rem;
border: 0;
background: none;
cursor: pointer;
color: white;
display: flex;
align-items: center;

&:focus {
  outline 2px solid white;
}
&:hover {
  svg {
    transform: scale(1.1)
  }
}
&::-moz-focus-inner {
  border: 0;
}
svg {
  outline: none;
  transition: transform 0.1s linear;
  padding-right: 0.4rem;

  .cls-1, .cls-2 {
    fill: none;
    stroke: #fff;
    stroke-linejoin: round;
    stroke-width: 5px;
  }

  .cls-2 {
    stroke-linecap: round;
  }
}

`

export const ShopSection = () => {
  // const [country, setCountry] = useState({})
  // useEffect(() => {
  //   // can use this to preselect shipping options/currency
  //   async function fetchData() {
  //     const result = await getGeoInfo()
  //     setCountry(result)
  //   }
  //   fetchData()
  // }, [])

  const handleClick = e => {
    trackCustomEvent({
      category: "Buy Button",
      action: "Click",
    })
  }

  return (
    <Container
      style={{
        backgroundColor: "rgb(220,0,08)",
        backgroundImage: `url(${chilli})`,
        backgroundSize: "cover",
      }}
    >
      {/* <Paragraph>hello person from {country.name && country.name}</Paragraph> */}
      <ShoppingBar>
        <CartButton className="snipcart-checkout">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="1.4rem"
            height="1.4rem"
            viewBox="0 0 73 69"
          >
            <title>cart-icon</title>
            <g>
              <polyline className="cls-1" points="13 12 70 12 66 46 18 46" />
              <polyline className="cls-2" points="18 46 12 4 3 3" />
              <circle className="cls-2" cx="25" cy="60" r="5" />
              <circle className="cls-2" cx="58" cy="60" r="5" />
            </g>
          </svg>

          <span>
            <span className="snipcart-items-count">0</span> ITEMS
          </span>
        </CartButton>
      </ShoppingBar>
      <ProductList>
        {products.zines.map((product, index) => (
          <ProductTile key={index}>
            <Slider slideWidth={300}>
              <ProductImage src={zineImage} />
              <ProductImage src={zinePage5} />
              <ProductImage src={zinePage6} />
              <ProductImage src={zinePage7} />
              <ProductImage src={zinePage8} />
            </Slider>
            <PContainer>
              <ProductTitle>{product.name}</ProductTitle>
              <ProductPrice>£{product.price.toFixed(2)}</ProductPrice>
            </PContainer>
            <ProductDescription
              dangerouslySetInnerHTML={{ __html: product.description }}
            />
            <Paragraph
              style={{
                textAlign: "left",
                fontSize: "0.8rem",
                lineHeight: "1.4",
              }}
            >
              SOLD OUT! THANK YOU SO MUCH!
              <br />
              {/* We have a limited number of zines with scuffs or marks on the
              covers that we'll sell directly to those that ask for £18 +
              shipping. <br />
              <br />
              If you're interested please email: <Email /> */}
            </Paragraph>
            {/* <Button
              className="snipcart-add-item"
              data-item-id={product.id}
              data-item-price={product.price.toFixed(2)}
              data-item-url={product.url}
              data-item-name={product.name}
              data-item-image={zineImage}
              data-item-weight={product.weight}
              onClick={handleClick}
            >
              Add to cart
            </Button> */}
          </ProductTile>
        ))}
        <ProductTile>
          <Slider slideWidth={300}>
            <ProductImage src={ebteefront} />
            <ProductImage src={ebteeback} />
          </Slider>
          <PContainer>
            <ProductTitle>Expert Horror X EAT BITTER T-Shirt</ProductTitle>
          </PContainer>
          <ProductDescription>
            Limited run. Buy directly from Expert Horror.
          </ProductDescription>
          <ButtonLink
            href="https://experthorror.com/collections/expert-horror/products/expert-horror"
            target="_blank"
          >
            Buy from Expert Horror
          </ButtonLink>
        </ProductTile>
      </ProductList>
    </Container>
  )
}
