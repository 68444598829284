import React, { useState } from "react"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
const Container = styled.div`
  width: ${props => props.slideWidth}px;
  height: ${props => props.totalHeight}px;
`

const SlidesContainer = styled.div`
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: brightness(1.1);
  }
`
const Slide = styled.div`
  width: ${props => props.slideWidth}px;
`
const Stage = styled.div`
  position: relative;
  width: ${props => props.slideWidth}px;
  height: ${props => props.slideWidth}px;
  overflow: hidden;
`
const SlideList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`
const SlideListItem = styled.li`
  width: ${props => props.thumbWidth}px;
  height: 60px;
  cursor: pointer;
  transition: all 0.2s ease;
  img {
    object-fit: cover;
    width: ${props => props.thumbWidth}px;
    height: ${props => props.thumbWidth}px;
  }
  &:hover {
    filter: brightness(1.25);
  }
`

const Slider = ({ children, slideWidth }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const numSlides = children.length
  const thumbWidth = slideWidth / numSlides

  const slides = children.map((child, index) => (
    <Slide key={`s-${index}`} slideWidth={slideWidth}>
      {child}
    </Slide>
  ))
  const thumbnails = children.map((child, index) => (
    <SlideListItem
      key={`sli-${index}`}
      thumbWidth={thumbWidth}
      onClick={() => {
        trackCustomEvent({
          category: "Slider Thumbnail",
          action: "Click",
          label: index.toString(),
        })
        return setSlideIndex(index)
      }}
    >
      {child}
    </SlideListItem>
  ))
  return (
    <Container slideWidth={slideWidth} totalHeight={slideWidth + thumbWidth}>
      <Stage slideWidth={slideWidth}>
        <SlidesContainer style={{ left: `-${slideWidth * slideIndex}px` }}>
          {slides}
        </SlidesContainer>
      </Stage>
      <SlideList>{thumbnails}</SlideList>
    </Container>
  )
}

export default Slider
