import React, { Component } from "react"
import { Container, Paragraph } from "components/common"
import addToMailchimp from "gatsby-plugin-mailchimp"
import CTAForm from "./CTAForm"

export class CTASection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      firstName: "",
      submitting: false,
      submitted: false,
      error: false,
    }
  }

  onChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onSubmit = event => {
    event.preventDefault()

    const { email, firstName } = this.state
    if (!email || !firstName) {
      this.handleError("Please complete all fields.")
      return
    }
    this.setState({ submitting: true })
    addToMailchimp(email, { FNAME: firstName }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        this.setState({ submitting: false })
        this.checkResult(data)
      })
      .catch(() => {})
  }

  checkResult = data => {
    if (data.result === "error") {
      this.handleError(data.msg)
      return
    }
    this.onSuccess()
  }

  handleError = error => {
    if (error.includes("already subscribed to list")) {
      error = `You've already RSVP'd under this email!`
    }
    this.setState({ error: error })
  }

  onSuccess = () => {
    this.setState({ submitted: true })
  }

  resetForm = () => {
    this.setState({
      email: "",
      firstName: "",
      submitted: false,
      error: false,
    })
  }

  render() {
    return (
      <Container style={{ backgroundColor: "black", padding: "3rem 0 0" }}>
        <Paragraph
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "1.6rem",
            padding: "0 1rem",
          }}
        >
          EAT BITTER AND GIVE ME YOUR EMAIL
        </Paragraph>
        <Paragraph>More goods coming soon, be the first to know.</Paragraph>
        <CTAForm
          onChange={this.onChange}
          handleSubmit={this.onSubmit}
          resetForm={this.resetForm}
          {...this.state}
        />
      </Container>
    )
  }
}
