import React from "react"
import Layout from "components/layout"
// import { Announcement } from "components/common"
import SEO from "components/seo"

import {
  IntroSection,
  AboutSection,
  CTASection,
  CreditSection,
  ShopSection,
  PressSection,
  Footer,
} from "components"

const IndexPage = () => (
  <Layout>
    <SEO
      title="EAT BITTER"
      keywords={[`lydia pang`, `hakka`, `cooking`, `eat bitter`]}
    />
    {/* <Announcement>
      <p>The Eat Bitter zine is now available for pre-order!</p>
    </Announcement> */}
    <IntroSection />
    <ShopSection />
    <AboutSection />
    <PressSection />
    <CTASection />
    <CreditSection />
    <Footer />
  </Layout>
)

export default IndexPage
