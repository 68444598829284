import React, { Fragment } from "react"
import styled from "styled-components"
import { Spinner, Paragraph, Button } from "components/common"
import { media } from "styles/styled-media"

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  min-height: 150px;
`

const Input = styled.input`
  border: 1px solid #ffffff;
  background: black;
  color: white;
  display: block;
  margin: 0 0.5rem 0.1em;
  padding: 0.8em 1.2em;
  text-transform: uppercase;
  width: 50%;
  ::placeholder {
    color: ${props => props.theme.placeholderTextColor};
  }
  max-width: 20rem;

  ${media.smallmobile`
    width: 80%;
    margin: 0 3rem 0.5em 3rem;
  `}
`
const TextInput = styled(Input).attrs({
  type: "text",
})``

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${media.smallmobile`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  `}
`

const SubmitConfirmation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  color: ${props => props.theme.actionColor};
  text-align: center;
  margin: 1rem 0;
`

export default ({
  onChange,
  handleSubmit,
  firstName,
  lastName,
  email,
  submitting,
  submitted,
  resetForm,
  error,
}) => {
  return (
    <FormContainer onSubmit={handleSubmit}>
      {!submitted ? (
        !submitting ? (
          <Fragment>
            <Row>
              <TextInput
                name="firstName"
                placeholder="First Name"
                onChange={onChange}
                value={firstName}
              />
              {/* <TextInput
                name="lastName"
                placeholder="Last Name"
                onChange={onChange}
                value={lastName}
              /> */}
              <TextInput
                name="email"
                placeholder="you@email.com"
                onChange={onChange}
                value={email}
              />
            </Row>
            <Row>
              <ErrorText>{!!error ? error : ""}</ErrorText>
            </Row>

            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </Fragment>
        ) : (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )
      ) : (
        <SubmitConfirmation>
          <Paragraph>Thank you!</Paragraph>
          {/* <ResetFormButton onClick={resetForm}>
            RSVP another guest
          </ResetFormButton> */}
        </SubmitConfirmation>
      )}
    </FormContainer>
  )
}
