import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion"
import logo from "assets/img/eat-bitter-type.svg"
import hand from "assets/img/lydia-pang-arm.png"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  margin-bottom: 2rem;
`
const GraphicContainer = styled(motion.div)`
  width: 260px;
  height: 320px;
  padding: 140px 40px;
  position: relative;
  transition: transform 0.2s linear;
`

const ClipContainer = styled.div`
  width: 260px;
  height: 600px;
  overflow: hidden;
  position: relative;
  top: 0;
`
const Square = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 260px;
  height: 320px;
  background: white;
`
const Type = styled.img`
  width: 260px;
  margin-bottom: 4px;
`

const Hand = styled(motion.img)`
  width: 100px;
  z-index: 6;
  position: absolute;
  top: -40px;
  left: 20px;
`

// Hook

export const IntroSection = () => {
  const [scale, setScale] = useState(1)
  const maxScale = 1.8
  const { scrollY } = useViewportScroll()
  const ref = useRef()

  useEffect(() => {
    function handleResize() {
      let scale = Math.min(
        window.innerWidth / ref.current.offsetWidth,
        window.innerHeight / ref.current.offsetHeight
      )
      setScale(Math.min(scale, maxScale))
    }

    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const springConfig = {
    damping: 30,
    stiffness: 360,
    mass: 3,
  }

  const y = useSpring(
    useTransform(
      scrollY,
      [0, 500 * scale],
      [`-${50 * scale}px`, `${0 * scale}px`]
    ),
    springConfig
  )

  const squareY = useSpring(
    useTransform(scrollY, [0, 300 * scale], [`0`, `${20 * scale}px`]),
    springConfig
  )

  return (
    <Container>
      <GraphicContainer
        ref={ref}
        style={{
          top: squareY,
          transform: `scale(${scale})`,
          marginBottom: `${50 * scale}px`,
        }}
      >
        <Type src={logo} />
        <ClipContainer style={{ top: squareY }}>
          <Square />
          <Hand src={hand} style={{ top: y }} />
        </ClipContainer>
      </GraphicContainer>
    </Container>
  )
}
