import React from "react"
import styled from "styled-components"
import { media } from "styles/styled-media"
import { Paragraph, Container } from "components/common"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import calligraphy from "assets/img/eat-bitter-calligraphy.svg"
import type from "assets/img/eat-bitter-with-tagline.svg"
import wtclogo from "assets/img/welcome-to-chinatown-logo-bw.png"

const Blurb = styled.div`
  columns: 2 300px;
  column-gap: 2rem;
  column-fill: balance;
  orphans: 0;
  color: white;
  text-transform: uppercase;
  max-width: 930px;
  padding: 2rem;
  p:last-child,
  p:first-child {
    margin: 0;
  }
`

const Divider = styled.hr`
  background: rgba(255, 255, 255, 0.5);
  height: 1px;
  width: 12rem;
  margin: 2rem auto 4rem;
  border: 0;
`

const LockupContainer = styled.div`
  max-width: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
`
const Calligraphy = styled.img`
  width: 14%;
  margin-bottom: 20px;
`
const Type = styled.img`
  width: 100%;
`

const Testimonial = styled.div`
  max-width: 700px;
  margin: 1rem 4rem 4rem;
`
const Words = styled.blockquote`
  position: relative;
  font-size: 1rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  padding: 1em;
  padding-left: 1em;
  margin: 0 0 1em;
  text-align: justify;
  ${media.smallmobile`
  font-size: 0.8rem;
  `}

  &:before {
    content: "\\201C";

    position: absolute;
    right: 100%;
    font-size: 7em;
    line-height: 0px;
    top: 50px;
    ${media.mobile`
    top: 36px;
    font-size: 5em;
  `}
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    width: 120px;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
    left: 1em;
  }
`
const Cite = styled.cite`
  font-size: 0.8em;
  margin-left: 1.2em;
  font-style: normal;
  line-height: 1.6;
  display: block;
`
const Logo = styled.img`
  max-width: 160px;
`

const LogoLink = styled(OutboundLink)`
  border: 0;
  &:hover {
    border: 0;
  }
`
export const LogoLockup = () => (
  <LockupContainer>
    <Calligraphy src={calligraphy} />
    <Type src={type} />
  </LockupContainer>
)
export const AboutSection = () => (
  <Container>
    <LogoLockup />
    <Blurb>
      <Paragraph>
        Eat Bitter is a collection of short stories told through childhood
        recipes that are not for the faint hearted. This zine is an ode to and
        celebration of the spirit of The Pang family and their Hakka heritage. A
        year long project, eating bitter and the idea of resilience and patience
        in dark times, has grown a sharper cultural significance in 2020. A
        project that began as quiet introspection has grown into a conversation
        around protecting and celebrating Chinese culture and cuisine. A portion
        of the profits are being donated to Welcome To Chinatown, an initiative
        that supports Chinese businesses affected due to increased xenophobia in
        the wake&nbsp;of&nbsp;Covid-19.
      </Paragraph>
      <Paragraph>
        This collaboration spanned time zones and generations. The imagery was
        created by two female, half Chinese creatives; Creative Director Lydia
        Pang and Photographer Louise Hagger. The stories, recipes and memories
        were written by Lydia Pang, with help from her father
        and&nbsp;grandparents.
      </Paragraph>
      <Paragraph>
        The creative direction was born out of the Hakka spirit. Punk poster
        references echo the progressive and independent culture of Hakka
        ancestors, lucky Chinese red tones hero but with a purposeful nod into
        the blood red of meat. The imagery is visceral, textural and immediately
        grounds you in a sense of place and time, a feeling. This work is deeply
        personal and aims to shine a light on a culture long&nbsp;ignored.
      </Paragraph>
      <Paragraph>
        Because it’s time for everyone to know what Hakka tastes like.
      </Paragraph>
    </Blurb>
    <Divider />
    <Testimonial>
      <Words>
        In this moment of uprising, Lydia Pang’s response to crisis - is to
        create. As a quiet yet poignant act of resistance, Eat Bitter uses food,
        perhaps our most universal material, as a conduit for social change.
        Steeped in memory, tradition and life lessons, Pang holds space for her
        culture and community, emboldening the relationship between art and
        freedom.
      </Words>
      <Cite>
        Gem Fletcher,{" "}
        <OutboundLink
          href="https://podcasts.apple.com/gb/podcast/the-messy-truth/id1459128692"
          target="_blank"
          rel="noreferrer"
        >
          The Messy Truth
        </OutboundLink>
        ,{" "}
        <OutboundLink
          href="http://www.ripostemagazine.com/homepage"
          target="_blank"
          rel="noreferrer"
        >
          Riposte Magazine
        </OutboundLink>
        .
      </Cite>
    </Testimonial>
    <Testimonial>
      <Words>
        We are honored that Lydia has chosen to donate part of her proceeds to
        Welcome to Chinatown. This project resonates with deep reflection of
        identity and is a reminder to honor and preserve the history of those
        who come before us, which is core to Welcome to Chinatown’s mission too.
        We thank Lydia for her generosity to Welcome to Chinatown and for her
        creative heart that she’s sharing with the world through this project.
      </Words>
      <Cite>
        Victoria Lee and Jennifer Tam, co-founders of{" "}
        <OutboundLink
          href="https://www.welcometochinatown.com/"
          target="_blank"
          rel="noreferrer"
        >
          Welcome to Chinatown
        </OutboundLink>
        .
      </Cite>
    </Testimonial>
    <LogoLink
      href="https://www.welcometochinatown.com/"
      target="_blank"
      rel="noreferrer"
    >
      <Logo src={wtclogo} />
    </LogoLink>
  </Container>
)
