import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Container = styled.div`
  width: 38%;
  margin: 1rem 3% 0;
  font-size: 0.8rem;
  text-align: center;
`

const Name = styled.div`
  color: black;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1em;
`
const Role = styled.span`
  margin: 0.2rem 0;
  color: rgba(0, 0, 0, 0.6);
  display: block;
`

const Website = styled(OutboundLink)`
  margin: 0.2rem 0 0;
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  &:hover,
  &:visited {
    border: 0;
    text-decoration: underline;
    color: black;
  }
`

const Insta = styled(OutboundLink)`
  margin: 0.2rem 0 0;
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  &:hover,
  &:visited {
    border: 0;
    text-decoration: underline;
    color: black;
  }
`
export const Credit = ({ name, role, website, insta, instaUrl }) => (
  <Container>
    <Name>{name}</Name>
    {role && <Role>{role}</Role>}
    {website && (
      <Website href={`https://www.${website}`} target="_blank">
        {website}
      </Website>
    )}
    {insta && (
      <Insta
        href={
          instaUrl
            ? `https://instagram.com/${instaUrl}`
            : `https://instagram.com/${insta}`
        }
        target="_blank"
      >
        @{insta}
      </Insta>
    )}
  </Container>
)
