import React from "react"
import styled from "styled-components"
import { Container, Email } from "components/common"

const Copy = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  line-height: 1.4;
  color: white;
`

export const Footer = () => (
  <Container style={{ padding: 0 }}>
    <Copy>Questions? Feelings?</Copy>
    <Email />
  </Container>
)
