import React from "react"
import styled from "styled-components"
import { media } from "styles/styled-media"
import { Paragraph } from "components/common"
import vice from "assets/img/vice-munchies-logo.svg"
import hakkacookbook from "assets/img/hakka-cookbook-logo.png"
import lip from "assets/img/lecture-in-progress-logo.png"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  margin-bottom: 2rem;
`

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 930px;
  align-items: center;
`

const ExternalLink = styled.a`
  border: 0;
  margin: 30px;
  &:hover {
    border: 0;
  }
  ${media.smallmobile`
  margin: 10px;
`}
`

const Image = styled.img`
  width: 180px;
  ${media.smallmobile`
  width: 80px;
`}
`

const press = [
  {
    link:
      "https://www.vice.com/en_us/article/9357b3/lydia-pang-eat-bitter-zine-about-hakka-chinese-cuisine",
    logo: vice,
  },
  {
    link:
      "https://thehakkacookbook.com/2020/09/15/eat-bitter-a-new-magazine-that-records-lydia-pangs-family-hakka-recipes-and-stories/",
    logo: hakkacookbook,
  },
  { link: "https://lectureinprogress.com/journal/lydia-pang", logo: lip },
]

const PressLink = ({ logo, to }) => (
  <ExternalLink href={to} target="_blank">
    <Image src={logo} />
  </ExternalLink>
)

export const PressSection = () => {
  return (
    <Container>
      <Paragraph
        style={{
          textAlign: "center",
          color: "white",
          fontSize: "1.6rem",
          padding: "0 1rem",
        }}
      >
        FEATURED IN
      </Paragraph>
      <LinkContainer>
        {press.map((item, index) => (
          <PressLink logo={item.logo} to={item.link} key={"pl-" + index} />
        ))}
      </LinkContainer>
    </Container>
  )
}
