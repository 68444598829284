import React from "react"
import styled from "styled-components"

import { Container } from "components/common"
import { Credit } from "./Credit"

const Credits = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
`

export const CreditSection = () => (
  <Container style={{ padding: "5rem 0", backgroundColor: "rgb(220,0,08)" }}>
    <Credits>
      <Credit name={"Paw Paw & Gung Gung"} role={`Lydia's Grandparents`} />
      <Credit name={"Peter Pang"} role={`Lydia's Dad`} />
      <Credit
        name={"Lydia Pang"}
        role={"Creative Director"}
        insta={"lydia_pang_"}
      />
      <Credit
        name={"Louise Hagger"}
        role={"Photographer"}
        website={"louisehagger.co.uk"}
        insta={"louisehagger"}
      />
      <Credit
        name={"Roo Williams"}
        role={"Graphic & Web Designer/Developer"}
        website={"roowilliams.com"}
        insta={"roowilliams"}
      />
      <Credit
        name={"Valerie Berry"}
        role={"Food Stylist"}
        website={"valerieberry.com"}
        insta={"vavaberry"}
      />
      <Credit
        name={"Alexander Breeze"}
        role={"Set Designer/Prop Stylist"}
        website={"alexanderbreeze.com"}
        insta={"alexanderbreeze"}
      />
      <Credit
        name={"Henry Chung"}
        role={"Chinese Calligraphy"}
        website={"henrychung.co"}
        insta={"skankhen"}
      />
      <Credit
        name={"Song Soo Kim"}
        role={"Assistant Food Stylist"}
        insta={"kim_songsoo"}
      />
      <Credit
        name={"Sam Reeves"}
        role={"Photo Assistant/Retouching"}
        insta={"sampeterreeves"}
        instaUrl={"sam.peter.reeves"}
      />
    </Credits>
  </Container>
)
